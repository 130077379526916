var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clickChatButton, setChatEnabled, injectIntl, getProfileData, fillProfileData } from '@s7ui-kit/s7ui-kit';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderChat, HeaderLanguage, HeaderLogo, HeaderProfile, Header as S7Header, } from '@s7/header';
import { languages } from '../configs';
import { transformProfile } from '../lib/transformProfile';
import { isEmpty } from '../../../../services/utils/utils';
import { Box } from '@s7/base';
import { rem } from '@s7/styles';
import { getProfileLinkByAuthState } from '../lib/getProfileLinkByAuthState';
import { analytics } from '../../../../services/analytics/analyticsManager';
var mapStateToProps = function (state) { return ({
    lang: state.App.lang,
    profile: state.User.profile,
    isChatEnabled: state.App.isChatEnabled,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    clickChatButton: function () { return dispatch(clickChatButton()); },
    setChatEnabled: function (isChatEnabled) { return dispatch(setChatEnabled(isChatEnabled)); },
    fillProfileData: function (data) { return dispatch(fillProfileData({ data: data })); },
}); };
var NewPortalHeader = function (_a) {
    var lang = _a.lang, profile = _a.profile, isChatEnabled = _a.isChatEnabled, clickChatButton = _a.clickChatButton, setChatEnabled = _a.setChatEnabled, getMessage = _a.getMessage, words = _a.words, fillProfileData = _a.fillProfileData;
    var history = useHistory();
    var location = useLocation();
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var loadProfile = function () {
        setLoading(true);
        getProfileData({ lang: lang })
            .then(fillProfileData)
            .finally(function () { return setLoading(false); });
    };
    useEffect(function () {
        loadProfile();
    }, []);
    var onClickChat = function () {
        !isChatEnabled && setChatEnabled(true);
        clickChatButton();
    };
    var onLanguageChange = function (code) {
        if (code === lang) {
            return;
        }
        var re = new RegExp("^/(".concat(lang, ")/"));
        var pathname = location.pathname === '/' ? "/".concat(code, "/") : location.pathname.replace(re, "/".concat(code, "/"));
        history.push("".concat(pathname).concat(location.search).concat(location.hash));
        window.location.reload();
    };
    return (React.createElement(Box, { sx: { position: 'relative', height: rem(68) } },
        React.createElement(S7Header, { analytics: analytics, withinPortal: false, sx: { top: 'unset', left: 'unset' }, rightSlot: React.createElement(React.Fragment, null,
                React.createElement(HeaderProfile, __assign({ as: 'a', href: getProfileLinkByAuthState(isEmpty(profile) ? window.location.href : null), loading: loading, loginLabel: getMessage(words, 's7ui.kit.header.popup.login.come.in.title') }, (!isEmpty(profile) && {
                    profile: transformProfile(profile, lang),
                }))),
                React.createElement(HeaderLanguage, { data: languages, title: getMessage(words, 'com.s7.header.choose.lang'), value: lang, 
                    // TODO: Исправится в следующей версии ДС https://kaiten.s7.ru/space/84/boards/card/560310
                    //@ts-expect-error
                    onChange: onLanguageChange }),
                React.createElement(HeaderChat, { onClick: onClickChat })), leftSlot: React.createElement(HeaderLogo, { as: 'a', href: '/' }) })));
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewPortalHeader));
